<template>
  <v-app>
    <dashboard-core-drawer
      v-if="usuarioLogado"
      :expand-on-hover.sync="expandOnHover"
      :drawerExpanded.sync="drawerExpanded"
    />
    <dashboard-core-app-bar
      style="position: absolute"
      v-model="expandOnHover"
      @input="changeExpandDrawer"
    />
    <dashboard-core-view class="mt-n6" />
    <ambiente-info />
    <cor-bdgd-info />
  </v-app>
</template>

<script>
import drawerMixins from '@/mixins/drawerMixins';

export default {
  name: 'DashboardIndex',
  mixins: [drawerMixins],
  components: {
    DashboardCoreAppBar: () => import('@/components/core/AppBar'),
    DashboardCoreDrawer: () => import('@/components/core/Drawer'),
    DashboardCoreView: () => import('@/components/core/View'),
    AmbienteInfo: () => import('@/components/general/AmbienteInfo'),
    CorBdgdInfo: () => import('@/components/general/CorBdgdInfo')
  },
  data: () => ({
    expandOnHover: true,
    drawerExpanded: false,
    userStoredOnVuex: false
  }),
  computed: {
    usuarioLogado() {
      return this.$store.getters.getUserId;
    }
  },
  methods: {
    changeExpandDrawer() {
      this.drawerExpanded = !this.drawerExpanded;
      this.changeSizeHeaderDashBoard();
    }
  }
};
</script>
